import request from 'axios';
export const axiosPost = request.create({
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true,
    headers:{'Content-Type':"application/json; charset=utf-8"}
})
// export const server = "http://localhost:8080";
export const header = {'Content-Type':"application/json; charset=utf-8"};
export const server = "https://www.wakocs.jp/wqc";
//export const server = "http://202.222.80.238/wqc";
// export const server = "http://153.120.21.52:8080/wqc";
